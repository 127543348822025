html,
body {
  height: 100%;
}

body {
	display: flex;
  	flex-direction: column;
  	min-height: 100vh;
	background-color: color("brown", "lighten-5");
	font-family: "Lato";

	.cnil-banner {
		position: fixed;
		bottom: 0;
		z-index: 1000;
		width: 100%;

		background-color: #2f2f2f;
		padding: 10px 0;

		@media #{$small-and-down} {
			padding-right: 20px;
		}

		.close-banner {
			position: absolute;
			font-size: 1.2em;
			color: #fff;
			top: 8px;
			right: 15px;

			&:hover {
				color: #ccc;
			}
		}

		span {
			color: #fff;
			font-weight: 300;
			font-size: 14px;

			@media #{$small-and-down} {
    			font-size: 11px;
			}
		}

		a {
			color: #eee;
			font-size: 14px;

			@media #{$small-and-down} {
    			font-size: 11px;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

main {
	background-color: $background-color;
	flex: 1 0 auto;
	position: relative;

	.preloader-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 1;
		transition: opacity .5s ease;

		&.inactive {
			opacity: 0;
		}

		.preloader {
			width: 100%;
			position: absolute;
			top: 150px;

			img {
				display: block;
				margin: auto;
				width: 150px;
			}
		}
	}

	.content {
		height: 100%;
		transition: opacity 1s ease;
		
		&.inactive {
			pointer-events: none;
   			cursor: default;
   			opacity: 0;
		}
	}

	section {
		min-height: 350px;
	}
}

@media #{ $large-and-up } {

	.container {
		width: 85%;
	}
}

.btn-custom {
	background-color: $main-color-light;

	&:hover, &:focus, &:active {
		background-color: lighten($main-color-light, 10%);
	}
}

.btn-fill {
	width: 100%;
}

h1 {
    color: $main-color-light;
    font-family: 'Lato';
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 1.8em;
    letter-spacing: 6px;
    color: $main-color-light;
    border: 2px solid $main-color-light;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 0;
}

@media #{ $medium-and-down } {
	h1 {
		font-size: 1.8em;
	}
}

@media #{ $small-and-down } {
	h1 {
		width: 70%;
		font-size: 1em;
		padding: 15px 0;
		font-weight: 500;
	}
}

.btn-facebook {
    background-color: #3B5998;
    color: #fff;

    &:hover, &:active, &:focus {
		background-color: #2d4373;
    }
}

.btn-instagram {
    background-color: #f94877;
    color: #fff;

    &:hover, &:active, &:focus {
    	background-color: #f71752;
    }
}

.inactive {
	visibility: hidden;
	cursor: default;
	pointer-events: none;
}