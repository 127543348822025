section#contact {
    padding: 40px 0;

    .card {

        @media #{$large-and-up} {
            > .row > .col:nth-of-type(1) {
                padding-right: 0;
            }

            > .row > .col:nth-of-type(2) {
                padding-left: 0;
            }
        }

        @media #{$medium-and-down} {
            .card-action {
                margin-bottom: 20px;
            }
        }

        .card-content {
            position: relative;

            @media #{$large-and-up} {
                height: 335px;
            }

            .card-title {
                font-weight: normal;
                font-size: 1.8em;
                display: block;
                margin-bottom: 20px;

                i {
                    margin-right: 5px;
                    color: $secondary-color;
                }
            }

            .vertical-delimiter {
                position: absolute;
                bottom: 0;
                left: 49%;
                width: 1px;
                background-color: rgba(160,160,160,0.2);
                height: 75%;

                @media #{$medium-and-down} {
                    height: 68%;
                }

                @media #{$small-and-down} {
                    display: none;
                }
            }

            .horizontal-delimiter {
                position: absolute;
                left: 0;
                top: 82px;
                width: 100%;
                height: 1px;
                background-color: rgba(160,160,160,0.2);
            }

            @media #{$small-and-down} {
                > .row > .col:nth-of-type(2) {
                    margin-top: 30px;
                }
            }

            h5 {
                font-weight: 300;
                font-size: 22px;
                margin-bottom: 15px;  
            }

            p {
                font-weight: 300;
                font-size: 14px;
            }

            span, a {
                display: block;
                font-weight: 500;
                font-size: 13px;
            }

        }

        .card-map {
            #map_wrapper {
                height: 400px;

                @media #{$large-and-up} {
                    border-left: 1px solid rgba(160,160,160,0.2);
                }
    
                #map_canvas {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        a {
            color: $secondary-color;
        }
    }
}