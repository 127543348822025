$header-color: $main-color;
$header-height: 150px;
$row-height: 75px;

header.public-header {
    height: $header-height;

    @media screen and (max-width: 1150px) {
        height: auto;
    }

    nav {
        background-color: $header-color;
        height: $header-height;
        line-height: $header-height;

        @media screen and (max-width: 1150px) {
            height: auto;
        }

        .brand {
            position: absolute;

            .brand-logo {
                left: initial;
                transform: initial;
                line-height: 0px;

                img {
                    height: 150px;
                    padding: 10px 0;
                }

                @media #{$small-and-down} {
                    img {
                        height: 150px;
                    }
                }
            }
        }

        .row {
            margin: 0;
        }

        .slogan {
            font-style: italic;
            line-height: 30px;
            font-size: 1.35em;
            text-align: center;
        }

        .slogan-large {
            float: right;
            margin: 28px 10px 0 0;

            @media screen and (max-width: 1150px) {
                display: none;
            }
        }

        .slogan-normal {
            display: none;
            padding: 0 14px 0 0;
            margin: 15px 0 0 0;
            text-align: right;

            @media screen and (max-width: 1150px) {
                display: block;
            }

            @media #{$small-and-down} {
                margin: 10px 0 10px 0;
            }

            @media screen and (max-width: 420px) {
                text-align: center;
            }
        }

        .brand-title {
            line-height: normal;
            color: $secondary-color;
            font-size: 1.15em;
            text-align: center;

           
        }

        .brand-title-large {
            margin: 20px 0 0 145px;
            line-height: 35px;

            @media screen and (max-width: 1150px) {
                display: none;
            }
        }

        .brand-title-medium {
            display: none;
            float: right;
            margin: 15px 10px 0 0;
            line-height: 30px;

            @media screen and (min-width: $small-screen) and (max-width: 650px) {
                font-size: 1.05em;
            }

            @media screen and (min-width: $small-screen) and (max-width: 1150px) {
                display: block;
            }
        }

        .brand-title-small {
            margin: 10px 0 10px 120px;
            padding-right: 15px;
            font-size: 1.2em;
            text-align: right;
            display: none;

            @media #{$small-and-down} {
                display: block;
            }

            &:last-of-type {
                margin-left: 0;
            }
        }

        .tels {
            margin-top: 28px;
            float: right;

            .tel {
                height: 30px;
                line-height: 30px;

                float: none;

                a {
                    color: $secondary-color;
                    font-size: 1.2em;

                    @media #{$small-and-down} {
                        color: #fff;
                    }
                }
            }
        }

        .tabs {
            display: inline-block;
            float: right;
            height: $row-height;
            overflow-x: hidden;
            width: auto;

            @media #{$small-and-down} {
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                float: none;
                height: 54px;
            }

            .tab {
                position: relative;
                height: $row-height;
                line-height: $row-height;

                &.hidden {
                    display: none;
                }

                @media #{$medium-and-down} {
                    a {
                        padding: 0 14px;
                    }
                }

                @media screen and (max-width: 400px) {
                    a {
                        padding: 0 10px;
                    }
                }

                @media screen and (max-width: 360px) {
                    a {
                        padding: 0;
                    }
                }

                @media #{$small-and-down} {
                    height: 48px;
                    line-height: 48px;

                    a img {
                        height: 48px;
                        padding: 10px;
                        filter: opacity(0.5);
                    }

                    a span {
                        font-size: 7px;
                        font-weight: 300;
                        position: absolute;
                        bottom: -20px;
                        left: 0;
                        width: 100%;
                        text-align: center;
                    }
                }
            }

        }

        .indicator {
            display: none;
        }
    }
}