$main-color: rgba(0,0,0, .9);
$main-color-light: lighten($main-color, 15%) !default;
$main-color-dark: darken($main-color, 15%) !default;

$secondary-color: #F60101;
$secondary-color-light: lighten($secondary-color, 30%) !default;

$background-color: #F3F3F3;

$form-color: $main-color-light;

$danger-color: color('red', 'base');
$warning-color: color('deep-orange', 'base');
$success-color: color('green', 'base');
$info-color: color('light-blue', 'base');