.card-alert {
    background-color: color('shades', 'white');
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    margin: 0;
    padding: 20px;
    transition: box-shadow .25s;

    &.card-alert-hide {
        display: none;
    }

    &.card-error {
        background-color: $danger-color;
        color: color('shades', 'white');

        a { color: color('shades', 'white'); }
    }
}

.alert {
    border-radius: 0;
    margin: 0;
    padding: 15px;
    padding-right: 35px;
    font-weight: 100;
    transition: box-shadow .25s;
    color: color('shades', 'white');

    a {
        color: color('shades', 'white');
    }

    .close {
        position: relative;
        top: -2px;
        right: -21px;
        color: inherit;
        float: right;
        background: transparent;
        padding: 0;
        box-shadow: none;

        line-height: 1;
        height: 21px;
        font-size: 23px;
        text-shadow: 0 1px 0 #fff;
        font-weight: 500;
        opacity: .5;
    }

    &-hide {
        display: none;
    }

    &-danger {
        background-color: $danger-color;
    }

    &-success {
        background-color: $success-color;
    }

    &-warning {
        background-color: $warning-color;
    }

    &-info {
        background-color: $info-color;
    }
}