/* This stylesheet generated by Transfonter (http://transfonter.org) on February 8, 2017 11:49 AM */
@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Hairline.eot');
	src: local('Lato Hairline'), local('Lato-Hairline'),
		url('../fonts/Lato/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Hairline.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Hairline.woff') format('woff'),
		url('../fonts/Lato/Lato-Hairline.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Hairline.svg#Lato-Hairline') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-HairlineItalic.eot');
	src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
		url('../fonts/Lato/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-HairlineItalic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-HairlineItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-HairlineItalic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-HairlineItalic.svg#Lato-HairlineItalic') format('svg');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Thin.eot');
	src: local('Lato Thin'), local('Lato-Thin'),
		url('../fonts/Lato/Lato-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Thin.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Thin.woff') format('woff'),
		url('../fonts/Lato/Lato-Thin.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Thin.svg#Lato-Thin') format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-ThinItalic.eot');
	src: local('Lato Thin Italic'), local('Lato-ThinItalic'),
		url('../fonts/Lato/Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-ThinItalic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-ThinItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-ThinItalic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-ThinItalic.svg#Lato-ThinItalic') format('svg');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Light.eot');
	src: local('Lato Light'), local('Lato-Light'),
		url('../fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Light.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Light.woff') format('woff'),
		url('../fonts/Lato/Lato-Light.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Light.svg#Lato-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-LightItalic.eot');
	src: local('Lato Light Italic'), local('Lato-LightItalic'),
		url('../fonts/Lato/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-LightItalic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-LightItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-LightItalic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-LightItalic.svg#Lato-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Regular.eot');
	src: local('Lato Regular'), local('Lato-Regular'),
		url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Regular.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Regular.woff') format('woff'),
		url('../fonts/Lato/Lato-Regular.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Regular.svg#Lato-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Italic.eot');
	src: local('Lato Italic'), local('Lato-Italic'),
		url('../fonts/Lato/Lato-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Italic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Italic.woff') format('woff'),
		url('../fonts/Lato/Lato-Italic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Italic.svg#Lato-Italic') format('svg');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Medium.eot');
	src: local('Lato Medium'), local('Lato-Medium'),
		url('../fonts/Lato/Lato-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Medium.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Medium.woff') format('woff'),
		url('../fonts/Lato/Lato-Medium.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Medium.svg#Lato-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-MediumItalic.eot');
	src: local('Lato Medium Italic'), local('Lato-MediumItalic'),
		url('../fonts/Lato/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-MediumItalic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-MediumItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-MediumItalic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-MediumItalic.svg#Lato-MediumItalic') format('svg');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Semibold.eot');
	src: local('Lato Semibold'), local('Lato-Semibold'),
		url('../fonts/Lato/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Semibold.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Semibold.woff') format('woff'),
		url('../fonts/Lato/Lato-Semibold.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Semibold.svg#Lato-Semibold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-SemiboldItalic.eot');
	src: local('Lato Semibold Italic'), local('Lato-SemiboldItalic'),
		url('../fonts/Lato/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-SemiboldItalic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-SemiboldItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-SemiboldItalic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-SemiboldItalic.svg#Lato-SemiboldItalic') format('svg');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Bold.eot');
	src: local('Lato Bold'), local('Lato-Bold'),
		url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Bold.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Bold.woff') format('woff'),
		url('../fonts/Lato/Lato-Bold.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Bold.svg#Lato-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-BoldItalic.eot');
	src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
		url('../fonts/Lato/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-BoldItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-BoldItalic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Heavy.eot');
	src: local('Lato Heavy'), local('Lato-Heavy'),
		url('../fonts/Lato/Lato-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Heavy.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Heavy.woff') format('woff'),
		url('../fonts/Lato/Lato-Heavy.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Heavy.svg#Lato-Heavy') format('svg');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-HeavyItalic.eot');
	src: local('Lato Heavy Italic'), local('Lato-HeavyItalic'),
		url('../fonts/Lato/Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-HeavyItalic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-HeavyItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-HeavyItalic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-HeavyItalic.svg#Lato-HeavyItalic') format('svg');
	font-weight: 800;
	font-style: italic;
}



@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Black.eot');
	src: local('Lato Black'), local('Lato-Black'),
		url('../fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Black.woff2') format('woff2'),
		url('../fonts/Lato/Lato-Black.woff') format('woff'),
		url('../fonts/Lato/Lato-Black.ttf') format('truetype'),
		url('../fonts/Lato/Lato-Black.svg#Lato-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-BlackItalic.eot');
	src: local('Lato Black Italic'), local('Lato-BlackItalic'),
		url('../fonts/Lato/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-BlackItalic.woff2') format('woff2'),
		url('../fonts/Lato/Lato-BlackItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-BlackItalic.ttf') format('truetype'),
		url('../fonts/Lato/Lato-BlackItalic.svg#Lato-BlackItalic') format('svg');
	font-weight: 900;
	font-style: italic;
}