.materialize-red {
  background-color: #e51c23 !important;
}

.materialize-red-text {
  color: #e51c23 !important;
}

.materialize-red.lighten-5 {
  background-color: #fdeaeb !important;
}

.materialize-red-text.text-lighten-5 {
  color: #fdeaeb !important;
}

.materialize-red.lighten-4 {
  background-color: #f8c1c3 !important;
}

.materialize-red-text.text-lighten-4 {
  color: #f8c1c3 !important;
}

.materialize-red.lighten-3 {
  background-color: #f3989b !important;
}

.materialize-red-text.text-lighten-3 {
  color: #f3989b !important;
}

.materialize-red.lighten-2 {
  background-color: #ee6e73 !important;
}

.materialize-red-text.text-lighten-2 {
  color: #ee6e73 !important;
}

.materialize-red.lighten-1 {
  background-color: #ea454b !important;
}

.materialize-red-text.text-lighten-1 {
  color: #ea454b !important;
}

.materialize-red.darken-1 {
  background-color: #d0181e !important;
}

.materialize-red-text.text-darken-1 {
  color: #d0181e !important;
}

.materialize-red.darken-2 {
  background-color: #b9151b !important;
}

.materialize-red-text.text-darken-2 {
  color: #b9151b !important;
}

.materialize-red.darken-3 {
  background-color: #a21318 !important;
}

.materialize-red-text.text-darken-3 {
  color: #a21318 !important;
}

.materialize-red.darken-4 {
  background-color: #8b1014 !important;
}

.materialize-red-text.text-darken-4 {
  color: #8b1014 !important;
}

.red {
  background-color: #F44336 !important;
}

.red-text {
  color: #F44336 !important;
}

.red.lighten-5 {
  background-color: #FFEBEE !important;
}

.red-text.text-lighten-5 {
  color: #FFEBEE !important;
}

.red.lighten-4 {
  background-color: #FFCDD2 !important;
}

.red-text.text-lighten-4 {
  color: #FFCDD2 !important;
}

.red.lighten-3 {
  background-color: #EF9A9A !important;
}

.red-text.text-lighten-3 {
  color: #EF9A9A !important;
}

.red.lighten-2 {
  background-color: #E57373 !important;
}

.red-text.text-lighten-2 {
  color: #E57373 !important;
}

.red.lighten-1 {
  background-color: #EF5350 !important;
}

.red-text.text-lighten-1 {
  color: #EF5350 !important;
}

.red.darken-1 {
  background-color: #E53935 !important;
}

.red-text.text-darken-1 {
  color: #E53935 !important;
}

.red.darken-2 {
  background-color: #D32F2F !important;
}

.red-text.text-darken-2 {
  color: #D32F2F !important;
}

.red.darken-3 {
  background-color: #C62828 !important;
}

.red-text.text-darken-3 {
  color: #C62828 !important;
}

.red.darken-4 {
  background-color: #B71C1C !important;
}

.red-text.text-darken-4 {
  color: #B71C1C !important;
}

.red.accent-1 {
  background-color: #FF8A80 !important;
}

.red-text.text-accent-1 {
  color: #FF8A80 !important;
}

.red.accent-2 {
  background-color: #FF5252 !important;
}

.red-text.text-accent-2 {
  color: #FF5252 !important;
}

.red.accent-3 {
  background-color: #FF1744 !important;
}

.red-text.text-accent-3 {
  color: #FF1744 !important;
}

.red.accent-4 {
  background-color: #D50000 !important;
}

.red-text.text-accent-4 {
  color: #D50000 !important;
}

.pink {
  background-color: #e91e63 !important;
}

.pink-text {
  color: #e91e63 !important;
}

.pink.lighten-5 {
  background-color: #fce4ec !important;
}

.pink-text.text-lighten-5 {
  color: #fce4ec !important;
}

.pink.lighten-4 {
  background-color: #f8bbd0 !important;
}

.pink-text.text-lighten-4 {
  color: #f8bbd0 !important;
}

.pink.lighten-3 {
  background-color: #f48fb1 !important;
}

.pink-text.text-lighten-3 {
  color: #f48fb1 !important;
}

.pink.lighten-2 {
  background-color: #f06292 !important;
}

.pink-text.text-lighten-2 {
  color: #f06292 !important;
}

.pink.lighten-1 {
  background-color: #ec407a !important;
}

.pink-text.text-lighten-1 {
  color: #ec407a !important;
}

.pink.darken-1 {
  background-color: #d81b60 !important;
}

.pink-text.text-darken-1 {
  color: #d81b60 !important;
}

.pink.darken-2 {
  background-color: #c2185b !important;
}

.pink-text.text-darken-2 {
  color: #c2185b !important;
}

.pink.darken-3 {
  background-color: #ad1457 !important;
}

.pink-text.text-darken-3 {
  color: #ad1457 !important;
}

.pink.darken-4 {
  background-color: #880e4f !important;
}

.pink-text.text-darken-4 {
  color: #880e4f !important;
}

.pink.accent-1 {
  background-color: #ff80ab !important;
}

.pink-text.text-accent-1 {
  color: #ff80ab !important;
}

.pink.accent-2 {
  background-color: #ff4081 !important;
}

.pink-text.text-accent-2 {
  color: #ff4081 !important;
}

.pink.accent-3 {
  background-color: #f50057 !important;
}

.pink-text.text-accent-3 {
  color: #f50057 !important;
}

.pink.accent-4 {
  background-color: #c51162 !important;
}

.pink-text.text-accent-4 {
  color: #c51162 !important;
}

.purple {
  background-color: #9c27b0 !important;
}

.purple-text {
  color: #9c27b0 !important;
}

.purple.lighten-5 {
  background-color: #f3e5f5 !important;
}

.purple-text.text-lighten-5 {
  color: #f3e5f5 !important;
}

.purple.lighten-4 {
  background-color: #e1bee7 !important;
}

.purple-text.text-lighten-4 {
  color: #e1bee7 !important;
}

.purple.lighten-3 {
  background-color: #ce93d8 !important;
}

.purple-text.text-lighten-3 {
  color: #ce93d8 !important;
}

.purple.lighten-2 {
  background-color: #ba68c8 !important;
}

.purple-text.text-lighten-2 {
  color: #ba68c8 !important;
}

.purple.lighten-1 {
  background-color: #ab47bc !important;
}

.purple-text.text-lighten-1 {
  color: #ab47bc !important;
}

.purple.darken-1 {
  background-color: #8e24aa !important;
}

.purple-text.text-darken-1 {
  color: #8e24aa !important;
}

.purple.darken-2 {
  background-color: #7b1fa2 !important;
}

.purple-text.text-darken-2 {
  color: #7b1fa2 !important;
}

.purple.darken-3 {
  background-color: #6a1b9a !important;
}

.purple-text.text-darken-3 {
  color: #6a1b9a !important;
}

.purple.darken-4 {
  background-color: #4a148c !important;
}

.purple-text.text-darken-4 {
  color: #4a148c !important;
}

.purple.accent-1 {
  background-color: #ea80fc !important;
}

.purple-text.text-accent-1 {
  color: #ea80fc !important;
}

.purple.accent-2 {
  background-color: #e040fb !important;
}

.purple-text.text-accent-2 {
  color: #e040fb !important;
}

.purple.accent-3 {
  background-color: #d500f9 !important;
}

.purple-text.text-accent-3 {
  color: #d500f9 !important;
}

.purple.accent-4 {
  background-color: #aa00ff !important;
}

.purple-text.text-accent-4 {
  color: #aa00ff !important;
}

.deep-purple {
  background-color: #673ab7 !important;
}

.deep-purple-text {
  color: #673ab7 !important;
}

.deep-purple.lighten-5 {
  background-color: #ede7f6 !important;
}

.deep-purple-text.text-lighten-5 {
  color: #ede7f6 !important;
}

.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important;
}

.deep-purple-text.text-lighten-4 {
  color: #d1c4e9 !important;
}

.deep-purple.lighten-3 {
  background-color: #b39ddb !important;
}

.deep-purple-text.text-lighten-3 {
  color: #b39ddb !important;
}

.deep-purple.lighten-2 {
  background-color: #9575cd !important;
}

.deep-purple-text.text-lighten-2 {
  color: #9575cd !important;
}

.deep-purple.lighten-1 {
  background-color: #7e57c2 !important;
}

.deep-purple-text.text-lighten-1 {
  color: #7e57c2 !important;
}

.deep-purple.darken-1 {
  background-color: #5e35b1 !important;
}

.deep-purple-text.text-darken-1 {
  color: #5e35b1 !important;
}

.deep-purple.darken-2 {
  background-color: #512da8 !important;
}

.deep-purple-text.text-darken-2 {
  color: #512da8 !important;
}

.deep-purple.darken-3 {
  background-color: #4527a0 !important;
}

.deep-purple-text.text-darken-3 {
  color: #4527a0 !important;
}

.deep-purple.darken-4 {
  background-color: #311b92 !important;
}

.deep-purple-text.text-darken-4 {
  color: #311b92 !important;
}

.deep-purple.accent-1 {
  background-color: #b388ff !important;
}

.deep-purple-text.text-accent-1 {
  color: #b388ff !important;
}

.deep-purple.accent-2 {
  background-color: #7c4dff !important;
}

.deep-purple-text.text-accent-2 {
  color: #7c4dff !important;
}

.deep-purple.accent-3 {
  background-color: #651fff !important;
}

.deep-purple-text.text-accent-3 {
  color: #651fff !important;
}

.deep-purple.accent-4 {
  background-color: #6200ea !important;
}

.deep-purple-text.text-accent-4 {
  color: #6200ea !important;
}

.indigo {
  background-color: #3f51b5 !important;
}

.indigo-text {
  color: #3f51b5 !important;
}

.indigo.lighten-5 {
  background-color: #e8eaf6 !important;
}

.indigo-text.text-lighten-5 {
  color: #e8eaf6 !important;
}

.indigo.lighten-4 {
  background-color: #c5cae9 !important;
}

.indigo-text.text-lighten-4 {
  color: #c5cae9 !important;
}

.indigo.lighten-3 {
  background-color: #9fa8da !important;
}

.indigo-text.text-lighten-3 {
  color: #9fa8da !important;
}

.indigo.lighten-2 {
  background-color: #7986cb !important;
}

.indigo-text.text-lighten-2 {
  color: #7986cb !important;
}

.indigo.lighten-1 {
  background-color: #5c6bc0 !important;
}

.indigo-text.text-lighten-1 {
  color: #5c6bc0 !important;
}

.indigo.darken-1 {
  background-color: #3949ab !important;
}

.indigo-text.text-darken-1 {
  color: #3949ab !important;
}

.indigo.darken-2 {
  background-color: #303f9f !important;
}

.indigo-text.text-darken-2 {
  color: #303f9f !important;
}

.indigo.darken-3 {
  background-color: #283593 !important;
}

.indigo-text.text-darken-3 {
  color: #283593 !important;
}

.indigo.darken-4 {
  background-color: #1a237e !important;
}

.indigo-text.text-darken-4 {
  color: #1a237e !important;
}

.indigo.accent-1 {
  background-color: #8c9eff !important;
}

.indigo-text.text-accent-1 {
  color: #8c9eff !important;
}

.indigo.accent-2 {
  background-color: #536dfe !important;
}

.indigo-text.text-accent-2 {
  color: #536dfe !important;
}

.indigo.accent-3 {
  background-color: #3d5afe !important;
}

.indigo-text.text-accent-3 {
  color: #3d5afe !important;
}

.indigo.accent-4 {
  background-color: #304ffe !important;
}

.indigo-text.text-accent-4 {
  color: #304ffe !important;
}

.blue {
  background-color: #2196F3 !important;
}

.blue-text {
  color: #2196F3 !important;
}

.blue.lighten-5 {
  background-color: #E3F2FD !important;
}

.blue-text.text-lighten-5 {
  color: #E3F2FD !important;
}

.blue.lighten-4 {
  background-color: #BBDEFB !important;
}

.blue-text.text-lighten-4 {
  color: #BBDEFB !important;
}

.blue.lighten-3 {
  background-color: #90CAF9 !important;
}

.blue-text.text-lighten-3 {
  color: #90CAF9 !important;
}

.blue.lighten-2 {
  background-color: #64B5F6 !important;
}

.blue-text.text-lighten-2 {
  color: #64B5F6 !important;
}

.blue.lighten-1 {
  background-color: #42A5F5 !important;
}

.blue-text.text-lighten-1 {
  color: #42A5F5 !important;
}

.blue.darken-1 {
  background-color: #1E88E5 !important;
}

.blue-text.text-darken-1 {
  color: #1E88E5 !important;
}

.blue.darken-2 {
  background-color: #1976D2 !important;
}

.blue-text.text-darken-2 {
  color: #1976D2 !important;
}

.blue.darken-3 {
  background-color: #1565C0 !important;
}

.blue-text.text-darken-3 {
  color: #1565C0 !important;
}

.blue.darken-4 {
  background-color: #0D47A1 !important;
}

.blue-text.text-darken-4 {
  color: #0D47A1 !important;
}

.blue.accent-1 {
  background-color: #82B1FF !important;
}

.blue-text.text-accent-1 {
  color: #82B1FF !important;
}

.blue.accent-2 {
  background-color: #448AFF !important;
}

.blue-text.text-accent-2 {
  color: #448AFF !important;
}

.blue.accent-3 {
  background-color: #2979FF !important;
}

.blue-text.text-accent-3 {
  color: #2979FF !important;
}

.blue.accent-4 {
  background-color: #2962FF !important;
}

.blue-text.text-accent-4 {
  color: #2962FF !important;
}

.light-blue {
  background-color: #03a9f4 !important;
}

.light-blue-text {
  color: #03a9f4 !important;
}

.light-blue.lighten-5 {
  background-color: #e1f5fe !important;
}

.light-blue-text.text-lighten-5 {
  color: #e1f5fe !important;
}

.light-blue.lighten-4 {
  background-color: #b3e5fc !important;
}

.light-blue-text.text-lighten-4 {
  color: #b3e5fc !important;
}

.light-blue.lighten-3 {
  background-color: #81d4fa !important;
}

.light-blue-text.text-lighten-3 {
  color: #81d4fa !important;
}

.light-blue.lighten-2 {
  background-color: #4fc3f7 !important;
}

.light-blue-text.text-lighten-2 {
  color: #4fc3f7 !important;
}

.light-blue.lighten-1 {
  background-color: #29b6f6 !important;
}

.light-blue-text.text-lighten-1 {
  color: #29b6f6 !important;
}

.light-blue.darken-1 {
  background-color: #039be5 !important;
}

.light-blue-text.text-darken-1 {
  color: #039be5 !important;
}

.light-blue.darken-2 {
  background-color: #0288d1 !important;
}

.light-blue-text.text-darken-2 {
  color: #0288d1 !important;
}

.light-blue.darken-3 {
  background-color: #0277bd !important;
}

.light-blue-text.text-darken-3 {
  color: #0277bd !important;
}

.light-blue.darken-4 {
  background-color: #01579b !important;
}

.light-blue-text.text-darken-4 {
  color: #01579b !important;
}

.light-blue.accent-1 {
  background-color: #80d8ff !important;
}

.light-blue-text.text-accent-1 {
  color: #80d8ff !important;
}

.light-blue.accent-2 {
  background-color: #40c4ff !important;
}

.light-blue-text.text-accent-2 {
  color: #40c4ff !important;
}

.light-blue.accent-3 {
  background-color: #00b0ff !important;
}

.light-blue-text.text-accent-3 {
  color: #00b0ff !important;
}

.light-blue.accent-4 {
  background-color: #0091ea !important;
}

.light-blue-text.text-accent-4 {
  color: #0091ea !important;
}

.cyan {
  background-color: #00bcd4 !important;
}

.cyan-text {
  color: #00bcd4 !important;
}

.cyan.lighten-5 {
  background-color: #e0f7fa !important;
}

.cyan-text.text-lighten-5 {
  color: #e0f7fa !important;
}

.cyan.lighten-4 {
  background-color: #b2ebf2 !important;
}

.cyan-text.text-lighten-4 {
  color: #b2ebf2 !important;
}

.cyan.lighten-3 {
  background-color: #80deea !important;
}

.cyan-text.text-lighten-3 {
  color: #80deea !important;
}

.cyan.lighten-2 {
  background-color: #4dd0e1 !important;
}

.cyan-text.text-lighten-2 {
  color: #4dd0e1 !important;
}

.cyan.lighten-1 {
  background-color: #26c6da !important;
}

.cyan-text.text-lighten-1 {
  color: #26c6da !important;
}

.cyan.darken-1 {
  background-color: #00acc1 !important;
}

.cyan-text.text-darken-1 {
  color: #00acc1 !important;
}

.cyan.darken-2 {
  background-color: #0097a7 !important;
}

.cyan-text.text-darken-2 {
  color: #0097a7 !important;
}

.cyan.darken-3 {
  background-color: #00838f !important;
}

.cyan-text.text-darken-3 {
  color: #00838f !important;
}

.cyan.darken-4 {
  background-color: #006064 !important;
}

.cyan-text.text-darken-4 {
  color: #006064 !important;
}

.cyan.accent-1 {
  background-color: #84ffff !important;
}

.cyan-text.text-accent-1 {
  color: #84ffff !important;
}

.cyan.accent-2 {
  background-color: #18ffff !important;
}

.cyan-text.text-accent-2 {
  color: #18ffff !important;
}

.cyan.accent-3 {
  background-color: #00e5ff !important;
}

.cyan-text.text-accent-3 {
  color: #00e5ff !important;
}

.cyan.accent-4 {
  background-color: #00b8d4 !important;
}

.cyan-text.text-accent-4 {
  color: #00b8d4 !important;
}

.teal {
  background-color: #009688 !important;
}

.teal-text {
  color: #009688 !important;
}

.teal.lighten-5 {
  background-color: #e0f2f1 !important;
}

.teal-text.text-lighten-5 {
  color: #e0f2f1 !important;
}

.teal.lighten-4 {
  background-color: #b2dfdb !important;
}

.teal-text.text-lighten-4 {
  color: #b2dfdb !important;
}

.teal.lighten-3 {
  background-color: #80cbc4 !important;
}

.teal-text.text-lighten-3 {
  color: #80cbc4 !important;
}

.teal.lighten-2 {
  background-color: #4db6ac !important;
}

.teal-text.text-lighten-2 {
  color: #4db6ac !important;
}

.teal.lighten-1 {
  background-color: #26a69a !important;
}

.teal-text.text-lighten-1 {
  color: #26a69a !important;
}

.teal.darken-1 {
  background-color: #00897b !important;
}

.teal-text.text-darken-1 {
  color: #00897b !important;
}

.teal.darken-2 {
  background-color: #00796b !important;
}

.teal-text.text-darken-2 {
  color: #00796b !important;
}

.teal.darken-3 {
  background-color: #00695c !important;
}

.teal-text.text-darken-3 {
  color: #00695c !important;
}

.teal.darken-4 {
  background-color: #004d40 !important;
}

.teal-text.text-darken-4 {
  color: #004d40 !important;
}

.teal.accent-1 {
  background-color: #a7ffeb !important;
}

.teal-text.text-accent-1 {
  color: #a7ffeb !important;
}

.teal.accent-2 {
  background-color: #64ffda !important;
}

.teal-text.text-accent-2 {
  color: #64ffda !important;
}

.teal.accent-3 {
  background-color: #1de9b6 !important;
}

.teal-text.text-accent-3 {
  color: #1de9b6 !important;
}

.teal.accent-4 {
  background-color: #00bfa5 !important;
}

.teal-text.text-accent-4 {
  color: #00bfa5 !important;
}

.green {
  background-color: #4CAF50 !important;
}

.green-text {
  color: #4CAF50 !important;
}

.green.lighten-5 {
  background-color: #E8F5E9 !important;
}

.green-text.text-lighten-5 {
  color: #E8F5E9 !important;
}

.green.lighten-4 {
  background-color: #C8E6C9 !important;
}

.green-text.text-lighten-4 {
  color: #C8E6C9 !important;
}

.green.lighten-3 {
  background-color: #A5D6A7 !important;
}

.green-text.text-lighten-3 {
  color: #A5D6A7 !important;
}

.green.lighten-2 {
  background-color: #81C784 !important;
}

.green-text.text-lighten-2 {
  color: #81C784 !important;
}

.green.lighten-1 {
  background-color: #66BB6A !important;
}

.green-text.text-lighten-1 {
  color: #66BB6A !important;
}

.green.darken-1 {
  background-color: #43A047 !important;
}

.green-text.text-darken-1 {
  color: #43A047 !important;
}

.green.darken-2 {
  background-color: #388E3C !important;
}

.green-text.text-darken-2 {
  color: #388E3C !important;
}

.green.darken-3 {
  background-color: #2E7D32 !important;
}

.green-text.text-darken-3 {
  color: #2E7D32 !important;
}

.green.darken-4 {
  background-color: #1B5E20 !important;
}

.green-text.text-darken-4 {
  color: #1B5E20 !important;
}

.green.accent-1 {
  background-color: #B9F6CA !important;
}

.green-text.text-accent-1 {
  color: #B9F6CA !important;
}

.green.accent-2 {
  background-color: #69F0AE !important;
}

.green-text.text-accent-2 {
  color: #69F0AE !important;
}

.green.accent-3 {
  background-color: #00E676 !important;
}

.green-text.text-accent-3 {
  color: #00E676 !important;
}

.green.accent-4 {
  background-color: #00C853 !important;
}

.green-text.text-accent-4 {
  color: #00C853 !important;
}

.light-green {
  background-color: #8bc34a !important;
}

.light-green-text {
  color: #8bc34a !important;
}

.light-green.lighten-5 {
  background-color: #f1f8e9 !important;
}

.light-green-text.text-lighten-5 {
  color: #f1f8e9 !important;
}

.light-green.lighten-4 {
  background-color: #dcedc8 !important;
}

.light-green-text.text-lighten-4 {
  color: #dcedc8 !important;
}

.light-green.lighten-3 {
  background-color: #c5e1a5 !important;
}

.light-green-text.text-lighten-3 {
  color: #c5e1a5 !important;
}

.light-green.lighten-2 {
  background-color: #aed581 !important;
}

.light-green-text.text-lighten-2 {
  color: #aed581 !important;
}

.light-green.lighten-1 {
  background-color: #9ccc65 !important;
}

.light-green-text.text-lighten-1 {
  color: #9ccc65 !important;
}

.light-green.darken-1 {
  background-color: #7cb342 !important;
}

.light-green-text.text-darken-1 {
  color: #7cb342 !important;
}

.light-green.darken-2 {
  background-color: #689f38 !important;
}

.light-green-text.text-darken-2 {
  color: #689f38 !important;
}

.light-green.darken-3 {
  background-color: #558b2f !important;
}

.light-green-text.text-darken-3 {
  color: #558b2f !important;
}

.light-green.darken-4 {
  background-color: #33691e !important;
}

.light-green-text.text-darken-4 {
  color: #33691e !important;
}

.light-green.accent-1 {
  background-color: #ccff90 !important;
}

.light-green-text.text-accent-1 {
  color: #ccff90 !important;
}

.light-green.accent-2 {
  background-color: #b2ff59 !important;
}

.light-green-text.text-accent-2 {
  color: #b2ff59 !important;
}

.light-green.accent-3 {
  background-color: #76ff03 !important;
}

.light-green-text.text-accent-3 {
  color: #76ff03 !important;
}

.light-green.accent-4 {
  background-color: #64dd17 !important;
}

.light-green-text.text-accent-4 {
  color: #64dd17 !important;
}

.lime {
  background-color: #cddc39 !important;
}

.lime-text {
  color: #cddc39 !important;
}

.lime.lighten-5 {
  background-color: #f9fbe7 !important;
}

.lime-text.text-lighten-5 {
  color: #f9fbe7 !important;
}

.lime.lighten-4 {
  background-color: #f0f4c3 !important;
}

.lime-text.text-lighten-4 {
  color: #f0f4c3 !important;
}

.lime.lighten-3 {
  background-color: #e6ee9c !important;
}

.lime-text.text-lighten-3 {
  color: #e6ee9c !important;
}

.lime.lighten-2 {
  background-color: #dce775 !important;
}

.lime-text.text-lighten-2 {
  color: #dce775 !important;
}

.lime.lighten-1 {
  background-color: #d4e157 !important;
}

.lime-text.text-lighten-1 {
  color: #d4e157 !important;
}

.lime.darken-1 {
  background-color: #c0ca33 !important;
}

.lime-text.text-darken-1 {
  color: #c0ca33 !important;
}

.lime.darken-2 {
  background-color: #afb42b !important;
}

.lime-text.text-darken-2 {
  color: #afb42b !important;
}

.lime.darken-3 {
  background-color: #9e9d24 !important;
}

.lime-text.text-darken-3 {
  color: #9e9d24 !important;
}

.lime.darken-4 {
  background-color: #827717 !important;
}

.lime-text.text-darken-4 {
  color: #827717 !important;
}

.lime.accent-1 {
  background-color: #f4ff81 !important;
}

.lime-text.text-accent-1 {
  color: #f4ff81 !important;
}

.lime.accent-2 {
  background-color: #eeff41 !important;
}

.lime-text.text-accent-2 {
  color: #eeff41 !important;
}

.lime.accent-3 {
  background-color: #c6ff00 !important;
}

.lime-text.text-accent-3 {
  color: #c6ff00 !important;
}

.lime.accent-4 {
  background-color: #aeea00 !important;
}

.lime-text.text-accent-4 {
  color: #aeea00 !important;
}

.yellow {
  background-color: #ffeb3b !important;
}

.yellow-text {
  color: #ffeb3b !important;
}

.yellow.lighten-5 {
  background-color: #fffde7 !important;
}

.yellow-text.text-lighten-5 {
  color: #fffde7 !important;
}

.yellow.lighten-4 {
  background-color: #fff9c4 !important;
}

.yellow-text.text-lighten-4 {
  color: #fff9c4 !important;
}

.yellow.lighten-3 {
  background-color: #fff59d !important;
}

.yellow-text.text-lighten-3 {
  color: #fff59d !important;
}

.yellow.lighten-2 {
  background-color: #fff176 !important;
}

.yellow-text.text-lighten-2 {
  color: #fff176 !important;
}

.yellow.lighten-1 {
  background-color: #ffee58 !important;
}

.yellow-text.text-lighten-1 {
  color: #ffee58 !important;
}

.yellow.darken-1 {
  background-color: #fdd835 !important;
}

.yellow-text.text-darken-1 {
  color: #fdd835 !important;
}

.yellow.darken-2 {
  background-color: #fbc02d !important;
}

.yellow-text.text-darken-2 {
  color: #fbc02d !important;
}

.yellow.darken-3 {
  background-color: #f9a825 !important;
}

.yellow-text.text-darken-3 {
  color: #f9a825 !important;
}

.yellow.darken-4 {
  background-color: #f57f17 !important;
}

.yellow-text.text-darken-4 {
  color: #f57f17 !important;
}

.yellow.accent-1 {
  background-color: #ffff8d !important;
}

.yellow-text.text-accent-1 {
  color: #ffff8d !important;
}

.yellow.accent-2 {
  background-color: #ffff00 !important;
}

.yellow-text.text-accent-2 {
  color: #ffff00 !important;
}

.yellow.accent-3 {
  background-color: #ffea00 !important;
}

.yellow-text.text-accent-3 {
  color: #ffea00 !important;
}

.yellow.accent-4 {
  background-color: #ffd600 !important;
}

.yellow-text.text-accent-4 {
  color: #ffd600 !important;
}

.amber {
  background-color: #ffc107 !important;
}

.amber-text {
  color: #ffc107 !important;
}

.amber.lighten-5 {
  background-color: #fff8e1 !important;
}

.amber-text.text-lighten-5 {
  color: #fff8e1 !important;
}

.amber.lighten-4 {
  background-color: #ffecb3 !important;
}

.amber-text.text-lighten-4 {
  color: #ffecb3 !important;
}

.amber.lighten-3 {
  background-color: #ffe082 !important;
}

.amber-text.text-lighten-3 {
  color: #ffe082 !important;
}

.amber.lighten-2 {
  background-color: #ffd54f !important;
}

.amber-text.text-lighten-2 {
  color: #ffd54f !important;
}

.amber.lighten-1 {
  background-color: #ffca28 !important;
}

.amber-text.text-lighten-1 {
  color: #ffca28 !important;
}

.amber.darken-1 {
  background-color: #ffb300 !important;
}

.amber-text.text-darken-1 {
  color: #ffb300 !important;
}

.amber.darken-2 {
  background-color: #ffa000 !important;
}

.amber-text.text-darken-2 {
  color: #ffa000 !important;
}

.amber.darken-3 {
  background-color: #ff8f00 !important;
}

.amber-text.text-darken-3 {
  color: #ff8f00 !important;
}

.amber.darken-4 {
  background-color: #ff6f00 !important;
}

.amber-text.text-darken-4 {
  color: #ff6f00 !important;
}

.amber.accent-1 {
  background-color: #ffe57f !important;
}

.amber-text.text-accent-1 {
  color: #ffe57f !important;
}

.amber.accent-2 {
  background-color: #ffd740 !important;
}

.amber-text.text-accent-2 {
  color: #ffd740 !important;
}

.amber.accent-3 {
  background-color: #ffc400 !important;
}

.amber-text.text-accent-3 {
  color: #ffc400 !important;
}

.amber.accent-4 {
  background-color: #ffab00 !important;
}

.amber-text.text-accent-4 {
  color: #ffab00 !important;
}

.orange {
  background-color: #ff9800 !important;
}

.orange-text {
  color: #ff9800 !important;
}

.orange.lighten-5 {
  background-color: #fff3e0 !important;
}

.orange-text.text-lighten-5 {
  color: #fff3e0 !important;
}

.orange.lighten-4 {
  background-color: #ffe0b2 !important;
}

.orange-text.text-lighten-4 {
  color: #ffe0b2 !important;
}

.orange.lighten-3 {
  background-color: #ffcc80 !important;
}

.orange-text.text-lighten-3 {
  color: #ffcc80 !important;
}

.orange.lighten-2 {
  background-color: #ffb74d !important;
}

.orange-text.text-lighten-2 {
  color: #ffb74d !important;
}

.orange.lighten-1 {
  background-color: #ffa726 !important;
}

.orange-text.text-lighten-1 {
  color: #ffa726 !important;
}

.orange.darken-1 {
  background-color: #fb8c00 !important;
}

.orange-text.text-darken-1 {
  color: #fb8c00 !important;
}

.orange.darken-2 {
  background-color: #f57c00 !important;
}

.orange-text.text-darken-2 {
  color: #f57c00 !important;
}

.orange.darken-3 {
  background-color: #ef6c00 !important;
}

.orange-text.text-darken-3 {
  color: #ef6c00 !important;
}

.orange.darken-4 {
  background-color: #e65100 !important;
}

.orange-text.text-darken-4 {
  color: #e65100 !important;
}

.orange.accent-1 {
  background-color: #ffd180 !important;
}

.orange-text.text-accent-1 {
  color: #ffd180 !important;
}

.orange.accent-2 {
  background-color: #ffab40 !important;
}

.orange-text.text-accent-2 {
  color: #ffab40 !important;
}

.orange.accent-3 {
  background-color: #ff9100 !important;
}

.orange-text.text-accent-3 {
  color: #ff9100 !important;
}

.orange.accent-4 {
  background-color: #ff6d00 !important;
}

.orange-text.text-accent-4 {
  color: #ff6d00 !important;
}

.deep-orange {
  background-color: #ff5722 !important;
}

.deep-orange-text {
  color: #ff5722 !important;
}

.deep-orange.lighten-5 {
  background-color: #fbe9e7 !important;
}

.deep-orange-text.text-lighten-5 {
  color: #fbe9e7 !important;
}

.deep-orange.lighten-4 {
  background-color: #ffccbc !important;
}

.deep-orange-text.text-lighten-4 {
  color: #ffccbc !important;
}

.deep-orange.lighten-3 {
  background-color: #ffab91 !important;
}

.deep-orange-text.text-lighten-3 {
  color: #ffab91 !important;
}

.deep-orange.lighten-2 {
  background-color: #ff8a65 !important;
}

.deep-orange-text.text-lighten-2 {
  color: #ff8a65 !important;
}

.deep-orange.lighten-1 {
  background-color: #ff7043 !important;
}

.deep-orange-text.text-lighten-1 {
  color: #ff7043 !important;
}

.deep-orange.darken-1 {
  background-color: #f4511e !important;
}

.deep-orange-text.text-darken-1 {
  color: #f4511e !important;
}

.deep-orange.darken-2 {
  background-color: #e64a19 !important;
}

.deep-orange-text.text-darken-2 {
  color: #e64a19 !important;
}

.deep-orange.darken-3 {
  background-color: #d84315 !important;
}

.deep-orange-text.text-darken-3 {
  color: #d84315 !important;
}

.deep-orange.darken-4 {
  background-color: #bf360c !important;
}

.deep-orange-text.text-darken-4 {
  color: #bf360c !important;
}

.deep-orange.accent-1 {
  background-color: #ff9e80 !important;
}

.deep-orange-text.text-accent-1 {
  color: #ff9e80 !important;
}

.deep-orange.accent-2 {
  background-color: #ff6e40 !important;
}

.deep-orange-text.text-accent-2 {
  color: #ff6e40 !important;
}

.deep-orange.accent-3 {
  background-color: #ff3d00 !important;
}

.deep-orange-text.text-accent-3 {
  color: #ff3d00 !important;
}

.deep-orange.accent-4 {
  background-color: #dd2c00 !important;
}

.deep-orange-text.text-accent-4 {
  color: #dd2c00 !important;
}

.brown {
  background-color: #795548 !important;
}

.brown-text {
  color: #795548 !important;
}

.brown.lighten-5 {
  background-color: #efebe9 !important;
}

.brown-text.text-lighten-5 {
  color: #efebe9 !important;
}

.brown.lighten-4 {
  background-color: #d7ccc8 !important;
}

.brown-text.text-lighten-4 {
  color: #d7ccc8 !important;
}

.brown.lighten-3 {
  background-color: #bcaaa4 !important;
}

.brown-text.text-lighten-3 {
  color: #bcaaa4 !important;
}

.brown.lighten-2 {
  background-color: #a1887f !important;
}

.brown-text.text-lighten-2 {
  color: #a1887f !important;
}

.brown.lighten-1 {
  background-color: #8d6e63 !important;
}

.brown-text.text-lighten-1 {
  color: #8d6e63 !important;
}

.brown.darken-1 {
  background-color: #6d4c41 !important;
}

.brown-text.text-darken-1 {
  color: #6d4c41 !important;
}

.brown.darken-2 {
  background-color: #5d4037 !important;
}

.brown-text.text-darken-2 {
  color: #5d4037 !important;
}

.brown.darken-3 {
  background-color: #4e342e !important;
}

.brown-text.text-darken-3 {
  color: #4e342e !important;
}

.brown.darken-4 {
  background-color: #3e2723 !important;
}

.brown-text.text-darken-4 {
  color: #3e2723 !important;
}

.blue-grey {
  background-color: #607d8b !important;
}

.blue-grey-text {
  color: #607d8b !important;
}

.blue-grey.lighten-5 {
  background-color: #eceff1 !important;
}

.blue-grey-text.text-lighten-5 {
  color: #eceff1 !important;
}

.blue-grey.lighten-4 {
  background-color: #cfd8dc !important;
}

.blue-grey-text.text-lighten-4 {
  color: #cfd8dc !important;
}

.blue-grey.lighten-3 {
  background-color: #b0bec5 !important;
}

.blue-grey-text.text-lighten-3 {
  color: #b0bec5 !important;
}

.blue-grey.lighten-2 {
  background-color: #90a4ae !important;
}

.blue-grey-text.text-lighten-2 {
  color: #90a4ae !important;
}

.blue-grey.lighten-1 {
  background-color: #78909c !important;
}

.blue-grey-text.text-lighten-1 {
  color: #78909c !important;
}

.blue-grey.darken-1 {
  background-color: #546e7a !important;
}

.blue-grey-text.text-darken-1 {
  color: #546e7a !important;
}

.blue-grey.darken-2 {
  background-color: #455a64 !important;
}

.blue-grey-text.text-darken-2 {
  color: #455a64 !important;
}

.blue-grey.darken-3 {
  background-color: #37474f !important;
}

.blue-grey-text.text-darken-3 {
  color: #37474f !important;
}

.blue-grey.darken-4 {
  background-color: #263238 !important;
}

.blue-grey-text.text-darken-4 {
  color: #263238 !important;
}

.grey {
  background-color: #9e9e9e !important;
}

.grey-text {
  color: #9e9e9e !important;
}

.grey.lighten-5 {
  background-color: #fafafa !important;
}

.grey-text.text-lighten-5 {
  color: #fafafa !important;
}

.grey.lighten-4 {
  background-color: #f5f5f5 !important;
}

.grey-text.text-lighten-4 {
  color: #f5f5f5 !important;
}

.grey.lighten-3 {
  background-color: #eeeeee !important;
}

.grey-text.text-lighten-3 {
  color: #eeeeee !important;
}

.grey.lighten-2 {
  background-color: #e0e0e0 !important;
}

.grey-text.text-lighten-2 {
  color: #e0e0e0 !important;
}

.grey.lighten-1 {
  background-color: #bdbdbd !important;
}

.grey-text.text-lighten-1 {
  color: #bdbdbd !important;
}

.grey.darken-1 {
  background-color: #757575 !important;
}

.grey-text.text-darken-1 {
  color: #757575 !important;
}

.grey.darken-2 {
  background-color: #616161 !important;
}

.grey-text.text-darken-2 {
  color: #616161 !important;
}

.grey.darken-3 {
  background-color: #424242 !important;
}

.grey-text.text-darken-3 {
  color: #424242 !important;
}

.grey.darken-4 {
  background-color: #212121 !important;
}

.grey-text.text-darken-4 {
  color: #212121 !important;
}

.black {
  background-color: #000000 !important;
}

.black-text {
  color: #000000 !important;
}

.white {
  background-color: #FFFFFF !important;
}

.white-text {
  color: #FFFFFF !important;
}

.transparent {
  background-color: transparent !important;
}

.transparent-text {
  color: transparent !important;
}

/* This stylesheet generated by Transfonter (http://transfonter.org) on February 8, 2017 11:49 AM */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Hairline.eot");
  src: local("Lato Hairline"), local("Lato-Hairline"), url("../fonts/Lato/Lato-Hairline.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Hairline.woff2") format("woff2"), url("../fonts/Lato/Lato-Hairline.woff") format("woff"), url("../fonts/Lato/Lato-Hairline.ttf") format("truetype"), url("../fonts/Lato/Lato-Hairline.svg#Lato-Hairline") format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-HairlineItalic.eot");
  src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"), url("../fonts/Lato/Lato-HairlineItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-HairlineItalic.woff2") format("woff2"), url("../fonts/Lato/Lato-HairlineItalic.woff") format("woff"), url("../fonts/Lato/Lato-HairlineItalic.ttf") format("truetype"), url("../fonts/Lato/Lato-HairlineItalic.svg#Lato-HairlineItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Thin.eot");
  src: local("Lato Thin"), local("Lato-Thin"), url("../fonts/Lato/Lato-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Thin.woff2") format("woff2"), url("../fonts/Lato/Lato-Thin.woff") format("woff"), url("../fonts/Lato/Lato-Thin.ttf") format("truetype"), url("../fonts/Lato/Lato-Thin.svg#Lato-Thin") format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-ThinItalic.eot");
  src: local("Lato Thin Italic"), local("Lato-ThinItalic"), url("../fonts/Lato/Lato-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-ThinItalic.woff2") format("woff2"), url("../fonts/Lato/Lato-ThinItalic.woff") format("woff"), url("../fonts/Lato/Lato-ThinItalic.ttf") format("truetype"), url("../fonts/Lato/Lato-ThinItalic.svg#Lato-ThinItalic") format("svg");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Light.eot");
  src: local("Lato Light"), local("Lato-Light"), url("../fonts/Lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Light.woff2") format("woff2"), url("../fonts/Lato/Lato-Light.woff") format("woff"), url("../fonts/Lato/Lato-Light.ttf") format("truetype"), url("../fonts/Lato/Lato-Light.svg#Lato-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-LightItalic.eot");
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url("../fonts/Lato/Lato-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-LightItalic.woff2") format("woff2"), url("../fonts/Lato/Lato-LightItalic.woff") format("woff"), url("../fonts/Lato/Lato-LightItalic.ttf") format("truetype"), url("../fonts/Lato/Lato-LightItalic.svg#Lato-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Regular.eot");
  src: local("Lato Regular"), local("Lato-Regular"), url("../fonts/Lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato/Lato-Regular.woff") format("woff"), url("../fonts/Lato/Lato-Regular.ttf") format("truetype"), url("../fonts/Lato/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Italic.eot");
  src: local("Lato Italic"), local("Lato-Italic"), url("../fonts/Lato/Lato-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Italic.woff2") format("woff2"), url("../fonts/Lato/Lato-Italic.woff") format("woff"), url("../fonts/Lato/Lato-Italic.ttf") format("truetype"), url("../fonts/Lato/Lato-Italic.svg#Lato-Italic") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Medium.eot");
  src: local("Lato Medium"), local("Lato-Medium"), url("../fonts/Lato/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Medium.woff2") format("woff2"), url("../fonts/Lato/Lato-Medium.woff") format("woff"), url("../fonts/Lato/Lato-Medium.ttf") format("truetype"), url("../fonts/Lato/Lato-Medium.svg#Lato-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-MediumItalic.eot");
  src: local("Lato Medium Italic"), local("Lato-MediumItalic"), url("../fonts/Lato/Lato-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-MediumItalic.woff2") format("woff2"), url("../fonts/Lato/Lato-MediumItalic.woff") format("woff"), url("../fonts/Lato/Lato-MediumItalic.ttf") format("truetype"), url("../fonts/Lato/Lato-MediumItalic.svg#Lato-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Semibold.eot");
  src: local("Lato Semibold"), local("Lato-Semibold"), url("../fonts/Lato/Lato-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Semibold.woff2") format("woff2"), url("../fonts/Lato/Lato-Semibold.woff") format("woff"), url("../fonts/Lato/Lato-Semibold.ttf") format("truetype"), url("../fonts/Lato/Lato-Semibold.svg#Lato-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-SemiboldItalic.eot");
  src: local("Lato Semibold Italic"), local("Lato-SemiboldItalic"), url("../fonts/Lato/Lato-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-SemiboldItalic.woff2") format("woff2"), url("../fonts/Lato/Lato-SemiboldItalic.woff") format("woff"), url("../fonts/Lato/Lato-SemiboldItalic.ttf") format("truetype"), url("../fonts/Lato/Lato-SemiboldItalic.svg#Lato-SemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Bold.eot");
  src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/Lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato/Lato-Bold.woff") format("woff"), url("../fonts/Lato/Lato-Bold.ttf") format("truetype"), url("../fonts/Lato/Lato-Bold.svg#Lato-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-BoldItalic.eot");
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url("../fonts/Lato/Lato-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-BoldItalic.woff2") format("woff2"), url("../fonts/Lato/Lato-BoldItalic.woff") format("woff"), url("../fonts/Lato/Lato-BoldItalic.ttf") format("truetype"), url("../fonts/Lato/Lato-BoldItalic.svg#Lato-BoldItalic") format("svg");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Heavy.eot");
  src: local("Lato Heavy"), local("Lato-Heavy"), url("../fonts/Lato/Lato-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Heavy.woff2") format("woff2"), url("../fonts/Lato/Lato-Heavy.woff") format("woff"), url("../fonts/Lato/Lato-Heavy.ttf") format("truetype"), url("../fonts/Lato/Lato-Heavy.svg#Lato-Heavy") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-HeavyItalic.eot");
  src: local("Lato Heavy Italic"), local("Lato-HeavyItalic"), url("../fonts/Lato/Lato-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-HeavyItalic.woff2") format("woff2"), url("../fonts/Lato/Lato-HeavyItalic.woff") format("woff"), url("../fonts/Lato/Lato-HeavyItalic.ttf") format("truetype"), url("../fonts/Lato/Lato-HeavyItalic.svg#Lato-HeavyItalic") format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-Black.eot");
  src: local("Lato Black"), local("Lato-Black"), url("../fonts/Lato/Lato-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-Black.woff2") format("woff2"), url("../fonts/Lato/Lato-Black.woff") format("woff"), url("../fonts/Lato/Lato-Black.ttf") format("truetype"), url("../fonts/Lato/Lato-Black.svg#Lato-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato/Lato-BlackItalic.eot");
  src: local("Lato Black Italic"), local("Lato-BlackItalic"), url("../fonts/Lato/Lato-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato/Lato-BlackItalic.woff2") format("woff2"), url("../fonts/Lato/Lato-BlackItalic.woff") format("woff"), url("../fonts/Lato/Lato-BlackItalic.ttf") format("truetype"), url("../fonts/Lato/Lato-BlackItalic.svg#Lato-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}

.input-field {
  margin-top: 20px;
}

.input-field input[type=text]:focus:not([readonly]),
.input-field input[type=email]:focus:not([readonly]),
.input-field input[type=password]:focus:not([readonly]),
.input-field input[type=number]:focus:not([readonly]),
.input-field textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid rgba(38, 38, 38, 0.9);
  box-shadow: 0 1px 0 0 rgba(38, 38, 38, 0.9);
}

.input-field input[type=text].valid, .input-field input[type=text]:focus.valid,
.input-field input[type=email].valid,
.input-field input[type=email]:focus.valid,
.input-field input[type=password].valid,
.input-field input[type=password]:focus.valid,
.input-field input[type=number].valid,
.input-field input[type=number]:focus.valid,
.input-field textarea.materialize-textarea.valid,
.input-field textarea.materialize-textarea:focus.valid {
  border-bottom: 1px solid #4CAF50;
  box-shadow: 0 1px 0 0 #4CAF50;
}

.input-field input[type=text].invalid, .input-field input[type=text]:focus.invalid,
.input-field input[type=email].invalid,
.input-field input[type=email]:focus.invalid,
.input-field input[type=password].invalid,
.input-field input[type=password]:focus.invalid,
.input-field input[type=number].invalid,
.input-field input[type=number]:focus.invalid,
.input-field textarea.materialize-textarea.invalid,
.input-field textarea.materialize-textarea:focus.invalid {
  border-bottom: 1px solid #F44336;
  box-shadow: 0 1px 0 0 #F44336;
}

.input-field input[type=text]:focus + label,
.input-field input[type=email]:focus + label,
.input-field input[type=password]:focus + label,
.input-field input[type=number]:focus + label,
.input-field textarea.materialize-textarea:focus + label {
  color: rgba(38, 38, 38, 0.9);
}

.input-field input[type=text].squared,
.input-field input[type=text].rounded,
.input-field input[type=email].squared,
.input-field input[type=email].rounded,
.input-field input[type=password].squared,
.input-field input[type=password].rounded,
.input-field input[type=number].squared,
.input-field input[type=number].rounded,
.input-field textarea.materialize-textarea.squared,
.input-field textarea.materialize-textarea.rounded {
  border: 1px solid #bdbdbd;
  box-shadow: none;
  padding: 6px 12px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0;
}

.input-field input[type=text].squared.rounded,
.input-field input[type=text].rounded.rounded,
.input-field input[type=email].squared.rounded,
.input-field input[type=email].rounded.rounded,
.input-field input[type=password].squared.rounded,
.input-field input[type=password].rounded.rounded,
.input-field input[type=number].squared.rounded,
.input-field input[type=number].rounded.rounded,
.input-field textarea.materialize-textarea.squared.rounded,
.input-field textarea.materialize-textarea.rounded.rounded {
  border-radius: 7px;
}

.input-field input[type=text].squared:focus:not([readonly]),
.input-field input[type=text].rounded:focus:not([readonly]),
.input-field input[type=email].squared:focus:not([readonly]),
.input-field input[type=email].rounded:focus:not([readonly]),
.input-field input[type=password].squared:focus:not([readonly]),
.input-field input[type=password].rounded:focus:not([readonly]),
.input-field input[type=number].squared:focus:not([readonly]),
.input-field input[type=number].rounded:focus:not([readonly]),
.input-field textarea.materialize-textarea.squared:focus:not([readonly]),
.input-field textarea.materialize-textarea.rounded:focus:not([readonly]) {
  box-shadow: none;
  border: 1px solid rgba(38, 38, 38, 0.9);
}

.input-field input[type=text].squared[readonly="readonly"],
.input-field input[type=text].rounded[readonly="readonly"],
.input-field input[type=email].squared[readonly="readonly"],
.input-field input[type=email].rounded[readonly="readonly"],
.input-field input[type=password].squared[readonly="readonly"],
.input-field input[type=password].rounded[readonly="readonly"],
.input-field input[type=number].squared[readonly="readonly"],
.input-field input[type=number].rounded[readonly="readonly"],
.input-field textarea.materialize-textarea.squared[readonly="readonly"],
.input-field textarea.materialize-textarea.rounded[readonly="readonly"] {
  cursor: not-allowed;
  border: 1px solid #bdbdbd;
}

.input-field input[type=text].squared.valid, .input-field input[type=text].squared:focus.valid,
.input-field input[type=text].rounded.valid,
.input-field input[type=text].rounded:focus.valid,
.input-field input[type=email].squared.valid,
.input-field input[type=email].squared:focus.valid,
.input-field input[type=email].rounded.valid,
.input-field input[type=email].rounded:focus.valid,
.input-field input[type=password].squared.valid,
.input-field input[type=password].squared:focus.valid,
.input-field input[type=password].rounded.valid,
.input-field input[type=password].rounded:focus.valid,
.input-field input[type=number].squared.valid,
.input-field input[type=number].squared:focus.valid,
.input-field input[type=number].rounded.valid,
.input-field input[type=number].rounded:focus.valid,
.input-field textarea.materialize-textarea.squared.valid,
.input-field textarea.materialize-textarea.squared:focus.valid,
.input-field textarea.materialize-textarea.rounded.valid,
.input-field textarea.materialize-textarea.rounded:focus.valid {
  border: 1px solid #4CAF50;
  box-shadow: none;
}

.input-field input[type=text].squared.invalid, .input-field input[type=text].squared:focus.invalid,
.input-field input[type=text].rounded.invalid,
.input-field input[type=text].rounded:focus.invalid,
.input-field input[type=email].squared.invalid,
.input-field input[type=email].squared:focus.invalid,
.input-field input[type=email].rounded.invalid,
.input-field input[type=email].rounded:focus.invalid,
.input-field input[type=password].squared.invalid,
.input-field input[type=password].squared:focus.invalid,
.input-field input[type=password].rounded.invalid,
.input-field input[type=password].rounded:focus.invalid,
.input-field input[type=number].squared.invalid,
.input-field input[type=number].squared:focus.invalid,
.input-field input[type=number].rounded.invalid,
.input-field input[type=number].rounded:focus.invalid,
.input-field textarea.materialize-textarea.squared.invalid,
.input-field textarea.materialize-textarea.squared:focus.invalid,
.input-field textarea.materialize-textarea.rounded.invalid,
.input-field textarea.materialize-textarea.rounded:focus.invalid {
  border: 1px solid #F44336;
  box-shadow: none;
}

.input-field input[type=text].squared + label,
.input-field input[type=text].rounded + label,
.input-field input[type=email].squared + label,
.input-field input[type=email].rounded + label,
.input-field input[type=password].squared + label,
.input-field input[type=password].rounded + label,
.input-field input[type=number].squared + label,
.input-field input[type=number].rounded + label,
.input-field textarea.materialize-textarea.squared + label,
.input-field textarea.materialize-textarea.rounded + label {
  transform: none;
  position: absolute;
  font-size: 0.8rem;
  top: -20px;
}

.input-field input[type=text].squared:focus:not([readonly]) + label,
.input-field input[type=text].rounded:focus:not([readonly]) + label,
.input-field input[type=email].squared:focus:not([readonly]) + label,
.input-field input[type=email].rounded:focus:not([readonly]) + label,
.input-field input[type=password].squared:focus:not([readonly]) + label,
.input-field input[type=password].rounded:focus:not([readonly]) + label,
.input-field input[type=number].squared:focus:not([readonly]) + label,
.input-field input[type=number].rounded:focus:not([readonly]) + label,
.input-field textarea.materialize-textarea.squared:focus:not([readonly]) + label,
.input-field textarea.materialize-textarea.rounded:focus:not([readonly]) + label {
  color: rgba(38, 38, 38, 0.9);
  transform: none;
}

.checkbox-field {
  margin-top: 10px !important;
}

input[type="radio"]:checked + label:after,
input[type="radio"]:checked + label:before,
input[type="radio"].with-gap:checked + label:before,
input[type="radio"].with-gap:checked + label:after {
  border-color: rgba(38, 38, 38, 0.9);
}

input[type="radio"]:checked + label:after,
input[type="radio"].with-gap:checked + label:after {
  background-color: rgba(38, 38, 38, 0.9);
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:checked + label:before {
  border-right: 2px solid rgba(38, 38, 38, 0.9);
  border-bottom: 2px solid rgba(38, 38, 38, 0.9);
}

input[type="checkbox"].filled-in:checked + label:after,
input[type="checkbox"]:checked + label:after {
  border: 2px solid rgba(38, 38, 38, 0.9);
  background-color: rgba(38, 38, 38, 0.9);
}

.input-field .prefix.active {
  color: rgba(38, 38, 38, 0.9);
}

.input-field .status {
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 10px;
}

.input-field .prefix + input + label + .status {
  padding-left: 42px;
}

.input-field .valid + label + .status,
.input-field .valid.status {
  color: #4CAF50;
}

.input-field .invalid + label + .status,
.input-field .invalid.status {
  color: #F44336;
}

.card-alert {
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 20px;
  transition: box-shadow .25s;
}

.card-alert.card-alert-hide {
  display: none;
}

.card-alert.card-error {
  background-color: #F44336;
  color: #FFFFFF;
}

.card-alert.card-error a {
  color: #FFFFFF;
}

.alert {
  border-radius: 0;
  margin: 0;
  padding: 15px;
  padding-right: 35px;
  font-weight: 100;
  transition: box-shadow .25s;
  color: #FFFFFF;
}

.alert a {
  color: #FFFFFF;
}

.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
  float: right;
  background: transparent;
  padding: 0;
  box-shadow: none;
  line-height: 1;
  height: 21px;
  font-size: 23px;
  text-shadow: 0 1px 0 #fff;
  font-weight: 500;
  opacity: .5;
}

.alert-hide {
  display: none;
}

.alert-danger {
  background-color: #F44336;
}

.alert-success {
  background-color: #4CAF50;
}

.alert-warning {
  background-color: #ff5722;
}

.alert-info {
  background-color: #03a9f4;
}

.low-hide {
  display: none;
}

.no-opacity {
  opacity: 0;
}

.inactive-link {
  pointer-events: none;
  cursor: default;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #efebe9;
  font-family: "Lato";
}

body .cnil-banner {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  background-color: #2f2f2f;
  padding: 10px 0;
}

@media only screen and (max-width: 600px) {
  body .cnil-banner {
    padding-right: 20px;
  }
}

body .cnil-banner .close-banner {
  position: absolute;
  font-size: 1.2em;
  color: #fff;
  top: 8px;
  right: 15px;
}

body .cnil-banner .close-banner:hover {
  color: #ccc;
}

body .cnil-banner span {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  body .cnil-banner span {
    font-size: 11px;
  }
}

body .cnil-banner a {
  color: #eee;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  body .cnil-banner a {
    font-size: 11px;
  }
}

body .cnil-banner a:hover {
  text-decoration: underline;
}

main {
  background-color: #F3F3F3;
  flex: 1 0 auto;
  position: relative;
}

main .preloader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity .5s ease;
}

main .preloader-wrapper.inactive {
  opacity: 0;
}

main .preloader-wrapper .preloader {
  width: 100%;
  position: absolute;
  top: 150px;
}

main .preloader-wrapper .preloader img {
  display: block;
  margin: auto;
  width: 150px;
}

main .content {
  height: 100%;
  transition: opacity 1s ease;
}

main .content.inactive {
  pointer-events: none;
  cursor: default;
  opacity: 0;
}

main section {
  min-height: 350px;
}

@media only screen and (min-width: 993px) {
  .container {
    width: 85%;
  }
}

.btn-custom {
  background-color: rgba(38, 38, 38, 0.9);
}

.btn-custom:hover, .btn-custom:focus, .btn-custom:active {
  background-color: rgba(64, 64, 64, 0.9);
}

.btn-fill {
  width: 100%;
}

h1 {
  color: rgba(38, 38, 38, 0.9);
  font-family: 'Lato';
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 1.8em;
  letter-spacing: 6px;
  color: rgba(38, 38, 38, 0.9);
  border: 2px solid rgba(38, 38, 38, 0.9);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
}

@media only screen and (max-width: 992px) {
  h1 {
    font-size: 1.8em;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    width: 70%;
    font-size: 1em;
    padding: 15px 0;
    font-weight: 500;
  }
}

.btn-facebook {
  background-color: #3B5998;
  color: #fff;
}

.btn-facebook:hover, .btn-facebook:active, .btn-facebook:focus {
  background-color: #2d4373;
}

.btn-instagram {
  background-color: #f94877;
  color: #fff;
}

.btn-instagram:hover, .btn-instagram:active, .btn-instagram:focus {
  background-color: #f71752;
}

.inactive {
  visibility: hidden;
  cursor: default;
  pointer-events: none;
}

header.public-header {
  height: 150px;
}

@media screen and (max-width: 1150px) {
  header.public-header {
    height: auto;
  }
}

header.public-header nav {
  background-color: rgba(0, 0, 0, 0.9);
  height: 150px;
  line-height: 150px;
}

@media screen and (max-width: 1150px) {
  header.public-header nav {
    height: auto;
  }
}

header.public-header nav .brand {
  position: absolute;
}

header.public-header nav .brand .brand-logo {
  left: initial;
  transform: initial;
  line-height: 0px;
}

header.public-header nav .brand .brand-logo img {
  height: 150px;
  padding: 10px 0;
}

@media only screen and (max-width: 600px) {
  header.public-header nav .brand .brand-logo img {
    height: 150px;
  }
}

header.public-header nav .row {
  margin: 0;
}

header.public-header nav .slogan {
  font-style: italic;
  line-height: 30px;
  font-size: 1.35em;
  text-align: center;
}

header.public-header nav .slogan-large {
  float: right;
  margin: 28px 10px 0 0;
}

@media screen and (max-width: 1150px) {
  header.public-header nav .slogan-large {
    display: none;
  }
}

header.public-header nav .slogan-normal {
  display: none;
  padding: 0 14px 0 0;
  margin: 15px 0 0 0;
  text-align: right;
}

@media screen and (max-width: 1150px) {
  header.public-header nav .slogan-normal {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  header.public-header nav .slogan-normal {
    margin: 10px 0 10px 0;
  }
}

@media screen and (max-width: 420px) {
  header.public-header nav .slogan-normal {
    text-align: center;
  }
}

header.public-header nav .brand-title {
  line-height: normal;
  color: #F60101;
  font-size: 1.15em;
  text-align: center;
}

header.public-header nav .brand-title-large {
  margin: 20px 0 0 145px;
  line-height: 35px;
}

@media screen and (max-width: 1150px) {
  header.public-header nav .brand-title-large {
    display: none;
  }
}

header.public-header nav .brand-title-medium {
  display: none;
  float: right;
  margin: 15px 10px 0 0;
  line-height: 30px;
}

@media screen and (min-width: 600px) and (max-width: 650px) {
  header.public-header nav .brand-title-medium {
    font-size: 1.05em;
  }
}

@media screen and (min-width: 600px) and (max-width: 1150px) {
  header.public-header nav .brand-title-medium {
    display: block;
  }
}

header.public-header nav .brand-title-small {
  margin: 10px 0 10px 120px;
  padding-right: 15px;
  font-size: 1.2em;
  text-align: right;
  display: none;
}

@media only screen and (max-width: 600px) {
  header.public-header nav .brand-title-small {
    display: block;
  }
}

header.public-header nav .brand-title-small:last-of-type {
  margin-left: 0;
}

header.public-header nav .tels {
  margin-top: 28px;
  float: right;
}

header.public-header nav .tels .tel {
  height: 30px;
  line-height: 30px;
  float: none;
}

header.public-header nav .tels .tel a {
  color: #F60101;
  font-size: 1.2em;
}

@media only screen and (max-width: 600px) {
  header.public-header nav .tels .tel a {
    color: #fff;
  }
}

header.public-header nav .tabs {
  display: inline-block;
  float: right;
  height: 75px;
  overflow-x: hidden;
  width: auto;
}

@media only screen and (max-width: 600px) {
  header.public-header nav .tabs {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    float: none;
    height: 54px;
  }
}

header.public-header nav .tabs .tab {
  position: relative;
  height: 75px;
  line-height: 75px;
}

header.public-header nav .tabs .tab.hidden {
  display: none;
}

@media only screen and (max-width: 992px) {
  header.public-header nav .tabs .tab a {
    padding: 0 14px;
  }
}

@media screen and (max-width: 400px) {
  header.public-header nav .tabs .tab a {
    padding: 0 10px;
  }
}

@media screen and (max-width: 360px) {
  header.public-header nav .tabs .tab a {
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  header.public-header nav .tabs .tab {
    height: 48px;
    line-height: 48px;
  }
  header.public-header nav .tabs .tab a img {
    height: 48px;
    padding: 10px;
    filter: opacity(0.5);
  }
  header.public-header nav .tabs .tab a span {
    font-size: 7px;
    font-weight: 300;
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}

header.public-header nav .indicator {
  display: none;
}

footer .footer-copyright {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.14), 0 -1px 5px 0 rgba(0, 0, 0, 0.12), 0 -1px 1px -2px rgba(0, 0, 0, 0.2);
  position: relative;
}

footer .footer-copyright .footer-absolute {
  position: absolute;
  width: 100%;
  top: -25px;
}

footer .footer-copyright .footer-absolute .footer-actions {
  margin: auto;
  width: 264px;
}

footer .footer-copyright .footer-absolute .footer-actions i {
  color: #000000;
}

footer .footer-copyright .footer-absolute .footer-actions .back-to-top {
  margin: 0 60px;
}

footer .footer-copyright .footer-info {
  padding: 45px 0 25px 0;
}

footer .footer-copyright .footer-info .row {
  margin: 0;
}

footer .footer-copyright .footer-info span {
  display: block;
}

footer .footer-copyright .footer-info span, footer .footer-copyright .footer-info a {
  color: #9e9e9e;
  font-weight: 300;
  text-transform: uppercase;
}

footer .footer-copyright .footer-info strong {
  color: #9e9e9e;
}

.slider .slides li .caption {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.slider .slides li .caption .container {
  position: relative;
  height: 100%;
}

.slider .slides li .caption .container h4 {
  position: absolute;
  width: 100%;
  font-size: 2.8rem;
  text-shadow: 3px 3px 0px black;
}

.slider .slides li .caption .container h4.top {
  top: 0;
  left: 0;
  margin-top: 30px;
}

.slider .slides li .caption .container h4.bottom {
  bottom: 0;
  left: 0;
  margin-bottom: 45px;
}

@media only screen and (max-width: 600px) {
  .slider .slides li .caption .container h4 {
    text-align: center;
    font-size: 1.6rem;
  }
}

section#news .container {
  padding: 40px 0;
  position: relative;
}

section#news .tabs .tab a:hover, section#news .tabs .tab a.active {
  color: #F60101;
}

section#news .tabs .tab a {
  color: #ff9191;
}

@media only screen and (max-width: 340px) {
  section#news .tabs .tab a {
    padding: 0;
  }
}

section#news .vertical-delimiter {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 1px;
  left: 50%;
  top: 40px;
  height: 90%;
}

section#news .fb-container {
  text-align: center;
  overflow: hidden;
  width: 100%;
}

section#news #instagram {
  position: relative;
}

section#news #instagram a {
  color: #F60101;
  margin-left: -40px;
}

section#news #instagram a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 446px) {
  section#news .hider {
    height: 200px;
    position: absolute;
    top: 1450px;
    background-color: #F3F3F3;
    width: 100%;
  }
}

@media screen and (min-width: 447px) {
  section#news .hider {
    height: 200px;
    position: absolute;
    top: 740px;
    background-color: #F3F3F3;
    width: 100%;
  }
}

@media screen and (min-width: 708px) {
  section#news .hider {
    height: 200px;
    position: absolute;
    top: 500px;
    background-color: #F3F3F3;
    width: 100%;
  }
}

@media screen and (min-width: 994px) {
  section#news .hider {
    height: 200px;
    position: absolute;
    top: 740px;
    background-color: #F3F3F3;
    width: 100%;
  }
}

@media screen and (min-width: 1466px) {
  section#news .hider {
    height: 200px;
    position: absolute;
    top: 500px;
    background-color: #F3F3F3;
    width: 100%;
  }
}

section#presentation .nav-wrapper {
  background-color: #E4E3E3;
}

section#presentation .nav-wrapper h3 {
  color: #F60101;
  font-weight: 300;
  font-size: 1.3rem;
}

section#presentation .nav-wrapper a {
  color: #F60101;
  font-weight: 300;
  font-size: 1em;
  padding-right: 15px;
}

section#presentation .nav-wrapper a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  section#presentation .nav-wrapper a i {
    font-size: 1.3rem;
    vertical-align: sub;
  }
}

section#presentation p.title {
  font-weight: 700;
}

section#presentation div.image {
  margin-top: 15px;
  text-align: center;
}

section#presentation div.image img {
  max-height: 300px;
}

section#presentation div.text {
  text-align: justify;
}

section#media .nav-wrapper {
  background-color: #E4E3E3;
  padding: 8px 0;
}

section#media .media-carousel .slick-slide {
  margin: 0 50px;
}

@media only screen and (max-width: 992px) {
  section#media .media-carousel .slick-slide {
    margin: 0 35px;
  }
}

@media only screen and (max-width: 600px) {
  section#media .media-carousel .slick-slide {
    margin: 0 20px;
  }
}

section#media .media-carousel .slick-slide img {
  height: 48px;
  width: auto;
}

@media only screen and (max-width: 600px) {
  section#media .media-carousel .slick-slide img {
    height: 40px;
  }
}

section#media .media {
  margin: 55px 0;
}

section#media .media .row {
  margin-bottom: 0;
}

section#media .media .media-illustration {
  position: relative;
  overflow: hidden;
}

section#media .media .media-illustration .media-picture {
  width: 100%;
  height: auto;
}

section#media .media .media-illustration .media-video {
  width: 100%;
  height: auto;
  background-color: #eee;
}

section#media .media .media-illustration .media-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: auto;
  height: 30px;
}

section#media .media .media-illustration .media-credits {
  position: absolute;
  background-color: #F60101;
  top: 0;
  right: 0;
  padding: 5px 15px;
  color: #fff;
  font-weight: 300;
  text-align: right;
  font-size: 1em;
}

section#media .media .media-title {
  margin: 0 0 20px 0;
}

section#media .media .media-title a {
  color: inherit;
}

section#media .media .media-title a:hover {
  text-decoration: underline;
}

section#media .media .media-description {
  font-weight: 300;
}

section#media .media .media-link {
  color: #F60101;
}

section#media .media .media-link:hover {
  text-decoration: underline;
}

section#pricing {
  padding-bottom: 30px;
}

section#pricing .tabs .tab a:hover, section#pricing .tabs .tab a.active {
  color: #F60101;
}

section#pricing .tabs .tab a {
  color: #ff9191;
}

@media only screen and (max-width: 340px) {
  section#pricing .tabs .tab a {
    padding: 0;
  }
}

section#pricing .title {
  text-align: center;
  font-size: 2.5rem;
  margin: 25px 0;
}

section#pricing .title small {
  font-weight: 300;
}

section#pricing .card .card-action a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating) {
  color: #F60101;
  height: 30px;
  line-height: 25px;
  padding-top: 2px;
}

@media only screen and (max-width: 1024px) {
  section#pricing .card.bilan .card-content .card-title {
    margin-bottom: 47px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 992px) {
  section#pricing .card.cours .card-content .card-title {
    margin-bottom: 47px;
  }
}

section#pricing .card .card-content .card-title {
  font-size: 22px;
  line-height: normal;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  section#pricing .card .card-content .card-title {
    font-size: 18px;
  }
}

section#pricing .card .card-content .card-title i {
  margin-right: 5px;
}

section#pricing .card .card-content .card-title.card-title-fixed-height {
  min-height: 54px;
}

section#pricing .card .card-content .card-info {
  display: block;
  margin-top: 8px;
  font-weight: 300;
  color: #616161;
}

section#pricing .card .card-content .card-info strong {
  color: #000;
}

section#pricing .card .card-content p {
  padding: 10px 0;
}

section#pricing .card .card-action {
  overflow: auto;
}

section#pricing .card .card-action span {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.9em;
  line-height: 30px;
}

section#pricing .card .card-action span i {
  margin-right: 5px;
}

section#contact {
  padding: 40px 0;
}

@media only screen and (min-width: 993px) {
  section#contact .card > .row > .col:nth-of-type(1) {
    padding-right: 0;
  }
  section#contact .card > .row > .col:nth-of-type(2) {
    padding-left: 0;
  }
}

@media only screen and (max-width: 992px) {
  section#contact .card .card-action {
    margin-bottom: 20px;
  }
}

section#contact .card .card-content {
  position: relative;
}

@media only screen and (min-width: 993px) {
  section#contact .card .card-content {
    height: 335px;
  }
}

section#contact .card .card-content .card-title {
  font-weight: normal;
  font-size: 1.8em;
  display: block;
  margin-bottom: 20px;
}

section#contact .card .card-content .card-title i {
  margin-right: 5px;
  color: #F60101;
}

section#contact .card .card-content .vertical-delimiter {
  position: absolute;
  bottom: 0;
  left: 49%;
  width: 1px;
  background-color: rgba(160, 160, 160, 0.2);
  height: 75%;
}

@media only screen and (max-width: 992px) {
  section#contact .card .card-content .vertical-delimiter {
    height: 68%;
  }
}

@media only screen and (max-width: 600px) {
  section#contact .card .card-content .vertical-delimiter {
    display: none;
  }
}

section#contact .card .card-content .horizontal-delimiter {
  position: absolute;
  left: 0;
  top: 82px;
  width: 100%;
  height: 1px;
  background-color: rgba(160, 160, 160, 0.2);
}

@media only screen and (max-width: 600px) {
  section#contact .card .card-content > .row > .col:nth-of-type(2) {
    margin-top: 30px;
  }
}

section#contact .card .card-content h5 {
  font-weight: 300;
  font-size: 22px;
  margin-bottom: 15px;
}

section#contact .card .card-content p {
  font-weight: 300;
  font-size: 14px;
}

section#contact .card .card-content span, section#contact .card .card-content a {
  display: block;
  font-weight: 500;
  font-size: 13px;
}

section#contact .card .card-map #map_wrapper {
  height: 400px;
}

@media only screen and (min-width: 993px) {
  section#contact .card .card-map #map_wrapper {
    border-left: 1px solid rgba(160, 160, 160, 0.2);
  }
}

section#contact .card .card-map #map_wrapper #map_canvas {
  width: 100%;
  height: 100%;
}

section#contact .card a {
  color: #F60101;
}

section#terms {
  padding: 20px 0;
}

section#terms h3 {
  font-size: 19px;
  font-weight: bold;
}

section#terms p {
  margin: 5px;
  font-weight: 300;
}
