footer .footer-copyright {
    z-index: 100;
    background-color: $main-color;
    box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, .14), 0 -1px 5px 0 rgba(0, 0, 0, .12), 0 -1px 1px -2px rgba(0, 0, 0, .2);
    position: relative;

    .footer-absolute {
        position: absolute;
        width: 100%;
        top: -25px;

        .footer-actions {

            margin: auto;
            width: 264px;

            i {
                color: color('shades', 'black');
            }

            .back-to-top {
                margin: 0 60px;
            }

        }
    }

    .footer-info {

        padding: 45px 0 25px 0;

        .row {
            margin: 0;
        }

        span {
            display: block;
        }

        span, a {
            color: color('grey', 'base');
            font-weight: 300;
            text-transform: uppercase;
        }

        strong {
            color: color('grey', 'base');
        }
    }
    
}