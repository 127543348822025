section#presentation {
    .nav-wrapper {
        background-color: #E4E3E3;

        h3 {
            color: $secondary-color;
            font-weight: 300;
            font-size: 1.3rem;
        }

        a {
            color: $secondary-color;
            font-weight: 300;
            font-size: 1em;
            padding-right: 15px;

            &:hover {
                text-decoration: underline;
            }

            @media #{$small-and-down} {
                i {
                    font-size: 1.3rem;
                    vertical-align: sub;
                }
            }
        }
    }

    p.title {
        font-weight: 700;
    }

    div.image {
        margin-top: 15px;
        text-align: center;

        img {
            max-height: 300px;
        }
    }

    div.text {
        text-align: justify;
    }
}