$form-color: $main-color-light;

.input-field {
    margin-top: 20px;

    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number],
    textarea.materialize-textarea {

        &:focus:not([readonly]) {
            border-bottom: 1px solid $form-color;
            box-shadow: 0 1px 0 0 $form-color;
        }

        &.valid, &:focus.valid {
            border-bottom: 1px solid color('green', 'base');
            box-shadow: 0 1px 0 0 color('green', 'base');
        }

        &.invalid, &:focus.invalid {
            border-bottom: 1px solid color('red', 'base');
            box-shadow: 0 1px 0 0 color('red', 'base');
        }

        &:focus + label {
            color: $form-color;
        }
    }

    input[type=text].squared,
    input[type=text].rounded,
    input[type=email].squared,
    input[type=email].rounded,
    input[type=password].squared,
    input[type=password].rounded,
    input[type=number].squared,
    input[type=number].rounded,
    textarea.materialize-textarea.squared,
    textarea.materialize-textarea.rounded {

        border: 1px solid color('grey', 'lighten-1');
        box-shadow: none;
        padding: 6px 12px;
        box-sizing: border-box;
        background-color: white;
        border-radius: 0;

        &.rounded {
            border-radius: 7px;
        }

        &:focus:not([readonly]) {
            box-shadow: none;
            border: 1px solid $form-color
        }

        &[readonly="readonly"] {
            cursor: not-allowed;
            border: 1px solid color('grey', 'lighten-1');
        }

        &.valid, &:focus.valid {
            border: 1px solid color('green', 'base');
            box-shadow: none;
        }

        &.invalid, &:focus.invalid {
            border:: 1px solid color('red', 'base');
            box-shadow: none;
        }

        + label {
            transform: none;
            position: absolute;
            font-size: 0.8rem;
            top: -20px;
        }

        &:focus:not([readonly]) + label {
            color: $form-color;
            transform: none;
        }

    }
}

.checkbox-field {
    margin-top: 10px !important;
}

input[type="radio"]:checked + label:after,
input[type="radio"]:checked + label:before,
input[type="radio"].with-gap:checked + label:before,
input[type="radio"].with-gap:checked + label:after {
    border-color: $form-color;
}

input[type="radio"]:checked + label:after,
input[type="radio"].with-gap:checked + label:after {
    background-color: $form-color;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:checked + label:before {
    border-right: 2px solid $form-color;
    border-bottom: 2px solid $form-color;
}

input[type="checkbox"].filled-in:checked + label:after,
input[type="checkbox"]:checked + label:after {
    border: 2px solid $form-color;
    background-color: $form-color;
}

.input-field .prefix.active {
  color: $form-color;
}

.input-field .status {
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 10px;
}

.input-field .prefix + input + label + .status {
  padding-left: 42px;
}

.input-field .valid + label + .status,
.input-field .valid.status {
  color: color('green', 'base');
}

.input-field .invalid + label + .status,
.input-field .invalid.status {
  color: color('red', 'base');
}
