section#media {

    .nav-wrapper {
        background-color: #E4E3E3;
        padding: 8px 0;
    }

    .media-carousel {

        .slick-slide {
            margin: 0 50px;

            @media #{$medium-and-down} {
                margin: 0 35px;
            }

            @media #{$small-and-down} {
                margin: 0 20px;
            }

            img {
                height: 48px;
                width: auto;

                @media #{$small-and-down} {
                    height: 40px;
                }
            }
        }

    }

    .media {
        margin: 55px 0;

        .row {
            margin-bottom: 0;
        }

        .media-illustration {
            position: relative;
            overflow: hidden;

            .media-picture {
                width: 100%;
                height: auto;
            }

            .media-video {
                width: 100%;
                height: auto;
                background-color: #eee;
            }

            .media-logo {
                position: absolute;
                top: 10px;
                left: 10px;
                width: auto;
                height: 30px;
            }

            .media-credits {
                position: absolute;
                background-color: $secondary-color;
                top: 0;
                right: 0;
                padding: 5px 15px;
                color: #fff;
                font-weight: 300;
                text-align: right;
                font-size: 1em;
            }
        }

        .media-title {
            margin: 0 0 20px 0;

            a {
                color: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .media-description {
            font-weight: 300;
        }

        .media-link {
            color: $secondary-color;

            &:hover {
                text-decoration: underline;
            }
        }

    }

}