section#terms {
	padding: 20px 0;

	h3 {
		font-size: 19px;
		font-weight: bold;
	}

	p {
		margin: 5px;
		font-weight: 300;
	}

}