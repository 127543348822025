section#pricing {
	padding-bottom: 30px;

    .tabs .tab a:hover, .tabs .tab a.active {
        color: $secondary-color;
    }

    .tabs .tab a {
        color: $secondary-color-light;

        @media only screen and (max-width: 340px) {
            padding: 0;
        }
    }

    .title {
    	text-align: center;
    	font-size: 2.5rem;
    	margin: 25px 0;

    	small {
    		font-weight: 300;
    	}
	}

	.card .card-action a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating) {
		color: #F60101;
		height: 30px;
		line-height: 25px;
		padding-top: 2px;
	}

    .card {

		@media only screen and (max-width : 1024px) {
			&.bilan .card-content .card-title {
				margin-bottom: 47px;
			}
		}

		@media #{$medium-only} {
			&.cours .card-content .card-title {
				margin-bottom: 47px;
			}
		}

    	.card-content {
    		.card-title {
    			font-size: 22px;
    			line-height: normal;
    			display: block;
    			text-align: center;
				margin-bottom: 20px;

    			@media #{$small-and-down} {
    				font-size: 18px;
				}

    			i {
					margin-right: 5px;
				}

				&.card-title-fixed-height {
					min-height: 54px;
				}
			}

    		.card-info {
				display: block;
				margin-top: 8px;
				font-weight: 300;
				color: #616161;

				strong {
					color: #000;
				}
			}

    		p {
    			padding: 10px 0;
    		}
    	}

    	.card-action {
	    	overflow: auto;

	    	span {
	    		text-transform: uppercase;
	    		font-weight: 300;
				font-size: 0.9em;
				line-height: 30px;

	    		i {
	    			margin-right: 5px;
	    		}
	    	}
	    }
    }

}