.slider .slides li .caption {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .container {
        position: relative;
        height: 100%;

        h4 {
            position: absolute;
            width: 100%;
            font-size: 2.8rem;
            text-shadow: 3px 3px 0px rgba(0, 0, 0, 1);

            &.top {
                top: 0;
                left: 0;
                margin-top: 30px;
            }

            &.bottom {
                bottom: 0;
                left: 0;
                margin-bottom: 45px;
            }

            @media #{$small-and-down} {
                text-align: center;
                font-size: 1.6rem;
            }
        }
    }
}