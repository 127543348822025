section#news {
	.container {
		padding: 40px 0;
		position: relative;
	}

	.tabs .tab a:hover, .tabs .tab a.active {
        color: $secondary-color;
    }

    .tabs .tab a {
        color: $secondary-color-light;

        @media only screen and (max-width: 340px) {
            padding: 0;
        }
    }

	.vertical-delimiter {
		position: absolute;
		background-color: rgba(0, 0, 0, 0.5);
	    width: 1px;
	    left: 50%;
	    top: 40px;
	    height: 90%;
	}

	.fb-container {
		text-align: center;
		overflow: hidden;
		width: 100%;
	}

	#instagram {
		position: relative;
		
		a {
		color: $secondary-color;
		margin-left: -40px;

			&:hover {
				text-decoration: underline;
			}
		}
	}
	
	@media screen and (max-width: 446px) {
		.hider {
			height: 200px;
			position: absolute;
			top: 1450px;
			background-color: $background-color;
			width: 100%;
		}
	}

	@media screen and (min-width: 447px) {
		.hider {
			height: 200px;
			position: absolute;
			top: 740px;
			background-color: $background-color;
			width: 100%;
		}
	}
	
	@media screen and (min-width: 708px) {
		.hider {
			height: 200px;
			position: absolute;
			top: 500px;
			background-color: $background-color;
			width: 100%;
		}
	}

	@media screen and (min-width: 994px) {
		.hider {
			height: 200px;
			position: absolute;
			top: 740px;
			background-color: $background-color;
			width: 100%;
		}
	}

	@media screen and (min-width: 1466px) {
		.hider {
			height: 200px;
			position: absolute;
			top: 500px;
			background-color: $background-color;
			width: 100%;
		}
	}
}