.low-hide {
    display: none;
}

.no-opacity {
	opacity: 0;
}

.inactive-link {
	pointer-events: none;
   	cursor: default;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-bold {
	font-weight: bold;
}

.text-italic {
	font-style: italic;
}